<template>
  <div class="program-user scroll" :class="{ 'program-user--overflow-hidden': showGalaxy }">
    <div class="onboard-card view-toggle-onboard">
      <OnboardingCard
        v-if="tutorial.showTutorial && !tutorial.modal && tutorial.viewMode && !$device.isMobile"
        @tutorial="handleTutorial"
      >
        <template #title>{{ $t('label.changeGalaxyView') }}</template>
        <template #label>{{ $t('supportText.changeGalaxyViewHere') }}</template>
      </OnboardingCard>
    </div>
    <div class="certificate-card" v-if="isCertificateAvailable && !$device.isMobile">
      <div class="heading">{{ $t('label.congratulations') }}</div>
      <div class="description">{{ $t('supportText.finishedProgram') }} "{{ program.name }}"</div>
      <DownloadCertBtn :certificate="personalizedCertificate" />
    </div>
    <div class="view-toggler">
      <span :class="{ active: showGalaxy }" @click="setShowGalaxy"><CardListIcon /></span>
      <span :class="{ active: !showGalaxy }" @click="setHideGalaxy"><PileListIcon /></span>
    </div>

    <transition name="component-fade" mode="out-in">
      <div
        key="galaxy"
        class="map-full"
        v-if="courses.length && showGalaxy && !$device.isMobile"
        ref="mapFullContainer"
      >
        <div class="map-full__map-objects-outer-container">
          <div class="map-full__map-objects-container" aria-label="Контейнер карты">
            <b-card
              v-if="isCertificateAvailable && $device.isMobile"
              class="map-object-card certificate"
            >
              <div class="map-object-card__cert-content">
                <div class="heading">{{ $t('label.congratulations') }}</div>
                <div class="description">
                  {{ $t('supportText.finishedProgram') }} "{{ program.name }}"
                </div>
                <DownloadCertBtn :certificate="personalizedCertificate" />
              </div>
            </b-card>
            <map-object
              v-for="(mapObjectConfig, index) in config.map_objects.slice(0, coursesLimit)"
              :index="index"
              :planetName="planets[index + 1]"
              :key="`map-object-${mapObjectConfig.map_object_id}`"
              :mapObjectConfig="mapObjectConfig"
              :course="courses[index]"
              :isProgramBlocking="isProgramBlocking"
              :containerConfig="config.container_settings"
              @click="handleTutorial('planet')"
            />
            <div class="onboard-card planet-onboard">
              <OnboardingCard
                v-if="
                  tutorial.showTutorial &&
                  !tutorial.modal &&
                  !tutorial.viewMode &&
                  tutorial.planet &&
                  !$device.isMobile
                "
                @tutorial="handleTutorial"
              >
                <template #title>{{ $t('label.planets') }}</template>
                <template #label
                  >{{
                    isProgramBlocking
                      ? $t('supportText.sequenceIsLocked')
                      : $t('supportText.sequenceIsFree')
                  }}
                </template>
              </OnboardingCard>
            </div>
          </div>
        </div>
      </div>
      <div
        key="cards"
        v-else-if="(!showGalaxy && program) || $device.isMobile"
        class="wrapper w-100"
      >
        <ProgramContentStudent v-if="program" :program="program" :courses="courses" />
      </div>
    </transition>
    <OnboardingModal :programTitle="programTitle" @tutorial="handleTutorial" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StudentService from '@/services/student.service';
import deviceMixin from '@/mixins/device.mixin';
import DownloadCertBtn from '@/components/DownloadCertBtn.vue';
import CardListIcon from '@/components/Icons/CardListIcon.vue';
import PileListIcon from '@/components/Icons/PileListIcon.vue';
import ProgramContentStudent from '@/components/Student/ProgramContent/ProgramContentStudent.vue';
import OnboardingModal from '@/components/Modals/OnboardingModal.vue';
import OnboardingCard from '@/components/Cards/OnboardingCard.vue';
import MapObject from './MapObject.vue';
import { mapConfig, planets } from '../../../config/app_config.json';
import { ActivityStatuses } from '../../utils/statusConstants';

export default {
  name: 'ProgramUser',
  components: {
    ProgramContentStudent,
    PileListIcon,
    CardListIcon,
    MapObject,
    DownloadCertBtn,
    OnboardingModal,
    OnboardingCard,
  },
  data() {
    return {
      courses: [],
      config: mapConfig,
      planets,
      program: null,
      isCertificateAvailable: false,
      certificateConfig: null,
      showGalaxy: true,
      tutorial: {
        showTutorial: true,
        modal: true,
        viewMode: true,
        planet: true,
      },
    };
  },
  mixins: [deviceMixin],
  async created() {
    const tutorial = localStorage.getItem('galaxy-tutorial');
    if (tutorial) {
      this.tutorial = JSON.parse(tutorial);
    }
    if (this.tutorial.viewMode) {
      this.showGalaxy = true;
    } else {
      this.showGalaxy = !!+localStorage.getItem('showGalaxy');
    }

    const { programId: id } = this.$route.params;

    await StudentService.getActivityById({ activityId: id, programId: id }).then((res) => {
      this.program = res.data;
      if (this.program.statistic.status === ActivityStatuses.done) {
        this.getProgramCertificate(id);
      }
    });

    await StudentService.getProgramCourses(id)
      .then(({ data }) => {
        this.courses = data ?? [];
      })
      .catch(console.log)
      .finally(() => (this.loading = false));

    this.triggerOnboarding();
    this.checkProgramForCompletion();
  },
  computed: {
    ...mapGetters({ user: 'auth/getUser' }),
    isProgramBlocking() {
      return !!this.program?.is_blocking;
    },
    coursesLimit() {
      return this.courses.length < 16 ? this.courses.length : 15;
    },
    programTitle() {
      return this.program?.name;
    },
    personalizedCertificate() {
      const userCert = this.certificateConfig;
      userCert.children[0].children.find(
        (el) => el.className === 'Text' && el.attrs.name === 'fio',
      ).attrs.text = `${this.user.first_name} ${this.user.last_name}`;
      userCert.children[0].children.find(
        (el) => el.className === 'Text' && el.attrs.name === 'score',
      ).attrs.text = `${this.program.statistic.score} / ${this.program.max_score}`;
      userCert.children[0].children.find(
        (el) => el.className === 'Text' && el.attrs.name === 'name',
      ).attrs.text = this.program.name;
      return userCert;
    },
  },
  methods: {
    triggerOnboarding() {
      if (this.tutorial.showTutorial && this.tutorial.modal && this.courses.length) {
        this.$bvModal.show('modal-onboarding');
      }
    },
    getProgramCertificate(id) {
      StudentService.getCertificate(id)
        .then(({ data }) => {
          this.isCertificateAvailable = true;
          this.certificateConfig = data.config;
        })
        .catch((error) => {
          if (error.response?.status !== 404) {
            console.log(error);
          }
        });
    },
    setShowGalaxy() {
      this.showGalaxy = true;
      localStorage.setItem('showGalaxy', '1');
      this.handleTutorial('viewMode');
    },
    setHideGalaxy() {
      this.showGalaxy = false;
      localStorage.setItem('showGalaxy', '0');
      this.handleTutorial('viewMode');
    },
    handleTutorial(value) {
      if (value === 'skip') {
        this.tutorial.showTutorial = false;
        localStorage.setItem('galaxy-tutorial', JSON.stringify(this.tutorial));
        return;
      }
      if (value === 'modal') {
        this.$bvModal.hide('modal-onboarding');
      }
      if (Object.keys(this.tutorial).includes(value)) {
        this.tutorial[value] = false;
        localStorage.setItem('galaxy-tutorial', JSON.stringify(this.tutorial));
      }
    },
    checkProgramForCompletion() {
      if (!this.courses.length && this.program.statistic.status !== ActivityStatuses.done) {
        StudentService.setActivityCompleted(this.program._id);
      }
    },
  },
  watch: {
    showGalaxy(bool) {
      if (bool) {
        this.triggerOnboarding();
      }
    },
  },
};
</script>
