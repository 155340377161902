<template>
  <div class="course-card-student" :class="{ locked: isLocked }" @click="openCourse">
    <div class="course-card-student__top">
      <div class="course-card-student__planet">
        <img :src="imageSrc" alt="planet" />
      </div>
      <div class="course-card-student__info info">
        <div class="course-card-student__planet-name">
          {{ $t('header.planet') }} {{ $t(`planets.${planets[index + 1]}`) }}
        </div>
        <div class="info__title">
          <div>{{ course.name || $t('placeholders.newCourse') }}</div>
          <div v-if="isLocked" class="info__lock-icon"><LockIcon /></div>
        </div>
        <div class="info__status">
          <ProgressStatus class="status" iconPosition="left" :status="status" />
        </div>
        <div v-if="false" class="info__temporary">{{ $t('supportText.statisticsWillBeSoon') }}</div>
        <!--        <div class="info__progress">-->
        <!--          <div-->
        <!--            class="lms-progress-bar"-->
        <!--            :class="{-->
        <!--              'lms-progress-bar&#45;&#45;doing': progressStatus === 2,-->
        <!--              'lms-progress-bar&#45;&#45;done': progressStatus === 4,-->
        <!--              'lms-progress-bar&#45;&#45;not-started': progressStatus === 1,-->
        <!--            }"-->
        <!--          >-->
        <!--            <b-progress :value="percent" :max="100"></b-progress>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="info__score">-->
        <!--          Баллы: <span>{{ course.statistic.score }} / {{ course.max_score }}</span>-->
        <!--        </div>-->
        <!--        <div class="info__progress-percent">-->
        <!--          Выполнено: <span>{{ percent }}%</span>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="course-card-student__bottom">
      <div v-if="false" class="course-card-student__date">12 - 25 сентября 2022</div>
      <div class="course-card-student__description">
        {{ course.description || $t('placeholders.needToKnowWhatCloudsHide') }}
      </div>
    </div>
  </div>
</template>

<script>
import ProgressStatus from '@/components/ProgressStatus.vue';
import {
  blocked, doing, done, notStarted, planetImagesUrl, rejected,
} from '@/utils/constants';
import LockIcon from '@/components/Icons/LockIcon.vue';
import deviceMixin from '@/mixins/device.mixin';
import { planets } from '../../../config/app_config.json';
import { ActivityStatuses } from '../../utils/statusConstants';

export default {
  name: 'CourseCardStudent',
  props: {
    course: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  components: {
    LockIcon,
    ProgressStatus,
  },
  mixins: [deviceMixin],
  data: () => ({
    planets,
  }),
  computed: {
    percent() {
      return Math.round((this.course.statistic.score / this.course.max_score) * 100) || 0;
    },
    isLocked() {
      return this.course.statistic.is_blocked;
    },
    status() {
      if (this.course.statistic.is_blocked) {
        return this.$device.isMobile ? { ...blocked, label: 'label.blocked2' } : blocked;
      }
      if (this.course.statistic.status === ActivityStatuses.done) {
        return this.$device.isMobile ? { ...done, label: 'label.passed2' } : done;
      }
      if (this.course.statistic.status === ActivityStatuses.doing) {
        return this.$device.isMobile ? { ...doing, label: 'label.inProgress2' } : doing;
      }
      if (this.course.statistic.status === ActivityStatuses.rejected) {
        return this.$device.isMobile ? { ...rejected, label: 'label.rejected2' } : rejected;
      }
      if (this.course.statistic.status === ActivityStatuses.notStarted) {
        return this.$device.isMobile ? { ...notStarted, label: 'label.notStarted2' } : notStarted;
      }
      return this.$device.isMobile ? { ...notStarted, label: 'label.notStarted2' } : notStarted;
    },
    imageSrc() {
      return `${planetImagesUrl}common/images/planetsWithNumbers/${this.index + 1}.png`;
    },
  },
  methods: {
    openCourse() {
      if (this.isLocked) {
        return;
      }
      const { programId } = this.$route.params;
      this.$router.push(`/course-user/${programId}/${this.course._id}`);
    },
  },
};
</script>
