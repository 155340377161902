<template>
  <div class="program-content-student">
    <div class="container">
      <div class="program-content-student__info">
        <div>{{ $t('header.educationProgram') }}</div>
        <div>
          {{ program.description }}
        </div>
        <AddButton
          v-if="courses.length"
          class="program-content-student__action-button"
          :title="$t('buttonLabels.goToEducation')"
          @click="openCurrentCourse"
        />
      </div>
      <div v-if="courses.length" class="program-content-student__courses">
        <div>{{ $t('header.courseList') }}</div>
        <div>
          <CourseCardStudent
            v-for="(course, index) in courses"
            :course="course"
            :index="index"
            :key="course._id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseCardStudent from '@/components/Cards/CourseCardStudent.vue';
import { ActivityStatuses } from '../../../utils/statusConstants';

export default {
  name: 'ProgramContentStudent',
  props: {
    program: {
      type: Object,
      required: true,
    },
    courses: {
      type: Array,
      required: true,
    },
  },
  components: {
    CourseCardStudent,
    AddButton: () => import('@/components/Buttons/AddButton.vue'),
  },
  methods: {
    openActivity(activity) {
      const { programId } = this.$route.params;
      return this.$router.push(`/course-user/${programId}/${activity._id}`);
    },
    openCurrentCourse() {
      const currentCourse = this.courses.find((c) => c.statistic.status
      === ActivityStatuses.doing);
      if (currentCourse) {
        return this.openActivity(currentCourse);
      }

      const notStarted = this.courses.find((c) => c.statistic.status
      === ActivityStatuses.notStarted);

      if (notStarted) {
        return this.openActivity(notStarted);
      }

      const completed = this.courses.find((c) => c.statistic.status
      === ActivityStatuses.done);
      if (completed) {
        return this.openActivity(completed);
      }

      const rejected = this.courses.find((c) => c.statistic.status
      === ActivityStatuses.rejected);
      if (rejected) {
        return this.openActivity(rejected);
      }
    },
  },
};
</script>
