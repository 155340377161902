<template>
  <div class="modal-onboarding">
    <b-modal id="modal-onboarding" centered ok-only no-close-on-backdrop>
      <template #modal-header> <div class="title">{{$t('label.welcome')}}</div> </template>
      <template #default>
        <div class="title">
          <!-- eslint-disable-next-line max-len -->
          {{`${$t('supportText.galaxyNameIs')} “${programTitle || $t('placeholders.newProgram')}”!`}}
        </div>
        <div class="title">{{$t('supportText.planetsAreCourses')}}</div>
      </template>
      <template #modal-footer>
        <AddButton :title="$t('buttonLabels.explore')" @click="$emit('tutorial', 'modal')"/>
      </template>
    </b-modal>
  </div>
</template>

<script>
import AddButton from '@/components/Buttons/AddButton.vue';

export default {
  name: 'OnboardingModal',
  components: { AddButton },
  props: {
    programTitle: {
      type: String,
      default: '',
    },
  },
};
</script>
