<template>
  <div
    class="map_object"
    :class="{ locked: isLocked }"
    :style="objectStyle"
    @mouseleave="isHover = false"
  >
    <div>
      <div class="object-svg" ref="mapObjectRef" v-html="SVGPath" />
      <transition name="component-fade" mode="out-in">
        <div
          class="object-label"
          v-if="isHover"
          ref="mapObjectLabel"
          @click="openCourse"
          @mouseover="isHover = true"
        >
          {{ planetStatus }}
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { planetImagesUrl } from '@/utils/constants';
import { ActivityStatuses } from '../../utils/statusConstants';

export default {
  props: {
    mapObjectConfig: {
      type: Object,
      required: true,
    },
    containerConfig: {
      type: Object,
      required: true,
    },
    course: {
      type: Object,
      default: () => ({}),
    },
    isProgramBlocking: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    planetName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    SVGPath: '',
    isHover: false,
  }),
  created() {
    this.getSVGPath();
  },
  computed: {
    status() {
      if (this.course.statistic.is_blocked) {
        return 'blocked';
      }
      if (this.course.statistic.status === ActivityStatuses.notStarted && this.isProgramBlocking) {
        return 'next-unlocked';
      }
      if (this.course.statistic.status === ActivityStatuses.doing) {
        return 'current';
      }
      if (this.course.statistic.status === ActivityStatuses.done) {
        return 'done';
      }
      return 'nostarted';
    },
    planetStatus() {
      if (this.course.statistic.is_blocked) {
        return this.$t('label.courseBlocked');
      }
      if (this.course.statistic.status === ActivityStatuses.doing) {
        return this.$t('label.courseInProcess');
      }
      if (this.course.statistic.status === ActivityStatuses.done) {
        return this.$t('label.courseDone');
      }
      return this.$t('label.courseNotStarted');
    },
    objectStyle() {
      return {
        top: `${(this.mapObjectConfig.y / this.containerConfig.height) * 100}%`,
        left: `${(this.mapObjectConfig.x / this.containerConfig.width) * 100}%`,
        width: `${(this.mapObjectConfig.width / this.containerConfig.width) * 100}%`,
        height: `${(this.mapObjectConfig.height / this.containerConfig.height) * 100}%`,

        background: `url("${planetImagesUrl}ru/images/map/planets/${this.index + 1}/${
          this.status
        }/passive.png") no-repeat center / contain`,
      };
    },
    isLocked() {
      return this.course.statistic?.is_blocked;
    },
  },
  methods: {
    async getSVGPath() {
      this.SVGPath = await fetch(
        `${planetImagesUrl}ru/images/map/planets/${this.index + 1}/${
          this.status
        }/contour.svg`,
      )
        .then((response) => response.body)
        .then((rb) => {
          const reader = rb.getReader();
          return new ReadableStream({
            start(controller) {
              function push() {
                reader.read().then(({ done, value }) => {
                  if (done) {
                    controller.close();
                    return;
                  }
                  controller.enqueue(value);
                  push();
                });
              }

              push();
            },
          });
        })
        .then((stream) => new Response(stream, { headers: { 'Content-Type': 'text/html' } }).text());

      this.$nextTick(this.setClickOnPath);
    },
    openCourse() {
      if (this.isLocked) {
        return;
      }
      this.$emit('click');
      const { programId } = this.$route.params;
      this.$router.push(`/course-user/${programId}/${this.course._id}`);
    },
    setClickOnPath() {
      const svgImage = this.$refs.mapObjectRef?.getElementsByTagName('svg')[0];
      if (svgImage) {
        Array.from(svgImage.children).forEach((path) => {
          if (!path.onclick) {
            path.onclick = this.openCourse;
            path.onmouseover = () => {
              this.isHover = true;
            };
          }
        });
      }
    },
  },
};
</script>
